<template lang="html">
  <div class="content">
    <span>Výsledek</span>
    <div class="results">
      <div class="group" v-for="result in results" :key="result">
        <div class="box">
          <div class="wrap">
            <p class="info">{{ result.title }}</p>
            <p class="info title phone" v-html="result.name"></p>
            <p class="info title">{{ result.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    values: Array
  },
  data() {
    return {
      results: [
        {
          title: 'Účet nalezen',
          name: 'Telefonní číslo',
          text: this.values[0]
        },
        {
          title: 'Promokód existuje',
          name: 'Promokód',
          text: this.values[1]
        },
        {
          title: 'Lze využít',
          name: 'Uživatel může promokód <span style="font-weight: 500; color: black;">' + this.values[1] + '</span> využít.'
        }
      ],
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.content {
  padding-top: 24px;
  margin-top: 64px;
  border-top: 1px solid $gray;
  span {
    font: normal 500 20px/normal Roboto;
    letter-spacing: normal;
    color: $black;
  }
  .results {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .group {
      position: relative;
      margin-left: 24px;
      .box {
        display: block;
        position: relative;
        background: $gray;
        width: 220px;
        height: calc(93px + 2px);
        box-sizing: border-box;
        -webkit-clip-path: polygon(
          0 6px,
          6px 0,
          0 0,
          100% 0,
          100% calc(100% - 6px),
          calc(100% - 6px) 100%,
          0 100%,
          0 0
        );
        clip-path: polygon(
          0 6px,
          6px 0,
          0 0,
          100% 0,
          100% calc(100% - 6px),
          calc(100% - 6px) 100%,
          0 100%,
          0 0
        );
        .wrap {
          font: normal 500 16px/1.2 Roboto;
          font-stretch: normal;
          letter-spacing: 0.16px;
          text-align: left;
          color: $black;
          position: absolute;
          width: calc(220px - 2px);
          top: 1px;
          left: 1px;
          border-style: none;
          height: 93px;
          padding: 16px 16px 0 16px;
          box-sizing: border-box;
          background: $white;
          margin: 0;
          -webkit-clip-path: polygon(
            0 6px,
            6px 0,
            0 0,
            100% 0,
            100% calc(100% - 6px),
            calc(100% - 6px) 100%,
            0 100%,
            0 0
          );
          clip-path: polygon(
            0 6px,
            6px 0,
            0 0,
            100% 0,
            100% calc(100% - 6px),
            calc(100% - 6px) 100%,
            0 100%,
            0 0
          );
          .info {
            margin: 0;
            font: normal 500 16px/normal Roboto;
            letter-spacing: 0.16px;
            color: $black;
            &.title {
              font-size: 14px;
              &.phone {
                font-weight: normal;
                color: $darkergray;
                margin-top: 8px;
              }
            }
          }
          &::after {
            content: '';
            position: absolute;
            background: url('~@/assets/icons/process-active.svg') no-repeat;
            width: 20px;
            height: 20px;
            right: 16px;
            top: 16px;
          }
        }
      }
    }
  }
}
</style>
