<template lang="html">
  <div class="input-border first">
    <label for="value"></label>
    <span class="text">Hodnota slevy</span>
    <div class="group">
      <div class="border-gr short">
        <input name="value" type="text" autocomplete="false"
               v-on:input="$emit('print-discount-value', $event.target.value)"
               :value="discountValue" />
      </div>
    </div>
  </div>

  <TheValueCurrency
    v-if="applierId === 2"
    :discount-currency="discountCurrency"
    @apply-discount-currency="applyDiscountCurrency"
  />
</template>

<script>
import TheValueCurrency from "@/components/UIComponents/PromocodeComponents/Configuration/Promocode/TheValueCurrency";
export default {
  components: {TheValueCurrency},
  emits: ['print-discount-value', 'print-discount-currency'],
  props: [
      'applierId', 'discountValue', 'discountCurrency'
  ],
  name: "TheValue",
  methods: {
    applyDiscountCurrency(value) {
      this.$emit(
          'print-discount-currency',
          value
      );
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_promocode.scss";
</style>