
import { defineComponent } from "vue";
// eslint-disable-next-line no-unused-vars
import {ConsumerGroup} from "@/models/Promocode/api.model";
import PromocodesModelConfig from "@/models/Promocode/config.model";

export default defineComponent({
  emits: [
    'refresh-consumer-groups-list',
    'invoke-error',
    'invoke-success',
  ],
  name: "TheAddConsumerGroup",
  data() {
    return {
      name: '',
      promocodesModulConfig: null
    };
  },
  created: async function() {
    this.promocodesModulConfig = await PromocodesModelConfig.getConfiguration();
  },
  methods: {
    addConsumerGroup: async function() {
      const consumerGroup = {
        name: this.name,
      } as ConsumerGroup;

      this.$http.post(this.promocodesModulConfig.apiBaseUrl + `promocodes/consumer-groups`, consumerGroup)
          .then((response) => {
            if (response.status !== 200) {
              this.$emit(
                  'invoke-error',
                  "CHYBA při ukládání změny: " + response.statusText
              );
            }
            this.$emit(
                'refresh-consumer-groups-list'
            );
            this.$emit(
                'invoke-success',
                'Hotovo!',
                'Skupina konzumentů (uživatelů) byla vytvořena.'
            );
          })
          .catch((error) => {
            this.$emit(
                'invoke-error',
                "CHYBA při ukládání změny: " + error
            );
          });
    }
  }
});
