<template lang="html">
  <div class="input-border first">
    <label for="promocodeUsedByUserLimit"></label>
    <span class="text">Počet použití jedním uživatelem</span>
    <div class="group">
      <div class="border-gr short">
        <input :value="numberOfUses" name="promocodeUsedByUserLimit" type="text" autocomplete="nope"
               v-on:input="$emit('print-number-of-uses', $event.target.value)"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['print-number-of-uses'],
  props: {
    numberOfUses: {
      type: Number
    }
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
		toggleInput() {
			this.visible = !this.visible;
		},
    toggleInputOutside() {
      this.visible = false;
    },
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import "@/assets/styles/_promocode.scss";
</style>
