
import { defineComponent } from "vue";
// eslint-disable-next-line no-unused-vars
import {Applier, Promocode} from "@/models/Promocode/api.model";
import PromocodesModelConfig from "@/models/Promocode/config.model";

export default defineComponent({
  name: "ThePromocodeListItem",
  emits: [
      'remove-promocode',
      'edit-promocode',
      'invoke-error',
      'invoke-success',
  ],
  props: {
    promocodeItem: Object,
    index: Number,
    appliers: Array
  },
  data() {
    return {
      promocodesModulConfig: null
    };
  },
  created: async function() {
    this.promocodesModulConfig = await PromocodesModelConfig.getConfiguration();
  },
  methods: {
    getApplier(appliers: Array<Applier>, applierId: number): Applier | null {
      let applierModel;
      appliers.forEach(function(item: Applier) {
        if (item.id == applierId) {
          applierModel = item;
        }
      });

      if (applierModel === undefined || applierModel === null) {
        return null;
      }
      return applierModel;
    },
    getApplierValue(appliers: Array<Applier>, applierId: number, discount: number | null, currency: string | null, finalPrice: number | null): string {
      const applier = this.getApplier(appliers, applierId);

      if (applier === null) {
        return '-';
      }

      if (discount !== null) {
        let discountString: string = discount.toString();
        if (currency !== null) {
          discountString = discountString + ' ' + currency;
        } else {
          discountString = discountString + ' %';
        }
        return discountString;
      }

      if (finalPrice !== null) {
        return finalPrice + ' ' + currency;
      }
    },
    getApplierName(appliers: Array<Applier>, applierId: number): string {
      const applier = this.getApplier(appliers, applierId);

      if (applier === null) {
        return 'unknown';
      }
      return applier.name;
    },
    editDiscount(index: number, promocode: Promocode) {
      this.$emit(
          'edit-promocode',
          { index: index, promocode: promocode }
      );
    },
    removeDiscount(index: number, promocode: Promocode) {
      if (!confirm("Opravdu chcete deaktivovat promokód?")) {
        return;
      }

      this.$http.delete(this.promocodesModulConfig.apiBaseUrl + `promocodes/promocodes/` + promocode.promocodeId)
        .then((response) => {
          if (response.status === 204) {
            this.$emit(
                'remove-promocode'
            );
            this.$emit(
                'invoke-success',
                'Hotovo!',
                'Slevový kód byl deaktivován!'
            );
          } else {
            this.$emit(
                'invoke-error',
                "CHYBA při ukládání změny: " + response.statusText
            );
          }
        })
        .catch((error) => {
          this.$emit(
              'invoke-error',
              "CHYBA při ukládání změny: " + error
          );
        });
    }
  }
});
