<template lang="html">
  <div class="container">
    <div class="text">
      {{ title }}
    </div>
    <div class="group">
      <div class="platform" :class="entity.class" v-for="entity in entities" :key="entity" >
        <label :for="name + '-' + entity.label" class="label-checkbox">
          <input
            v-on:change="addCountry(countries, entity.label)"
            :true-value="entity.label"
            :type="onlyOneCountry ? 'radio' : 'checkbox'" :id="name + '-' + entity.label" :name="name"
            :checked="countries.includes(entity.label)"
          />
          <span class="checkmark"></span>
        </label>
        <span class="text add">{{ entity.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: String,
    title: String,
    name: String,
    countries: Array,
    onlyOneCountry: Boolean
  },
  data() {
    return {
      entities: [
        {
          class: 'cz',
          label: 'cz',
          title: 'CZ'
        },
        {
          class: 'sk',
          label: 'sk',
          title: 'SK'
        },
        {
          class: 'hu',
          label: 'hu',
          title: 'HU'
        },
        {
          class: 'ro',
          label: 'ro',
          title: 'RO'
        }
      ]
    };
  },
  methods: {
    addCountry(countries, country) {
      if (!countries.includes(country)) {
        countries.push(country);
      } else {
        countries.forEach( (item, index) => {
          if(item === country) countries.splice(index,1);
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
.container {
  margin-top: 40px;
  .text {
    font: normal normal 14px/1.2 Roboto;
    font-stretch: normal;
    letter-spacing: 0.14px;
    text-align: left;
    color: $darkergray;
    margin-bottom: 24px;
    &.add {
      margin-left: .75rem;
    }
  }
  .group {
    display: flex;
    .platform {
      &.cz {
        padding-right: 48px;
      }
      &.sk {
        padding-right: 38px;
      }
      &.hu {
        padding-right: 38px;
      }
      .label-checkbox {
        position: relative;
        cursor: pointer;
        input[type=checkbox], input[type=radio] {
          opacity: 0;
          &:checked {
            ~ .checkmark {
              background-color: $red;
              border: 1px solid $red;
              &:after {
                display: block;
              }
            }
          }
        }
        .checkmark {
          position: absolute;
          top: -2px;
          left: 0;
          height: 18px;
          width: 20px;
          background-color: $white;
          border: 1px solid $gray;
          &::after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 1px;
            width: 3px;
            height: 10px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .text {
        font: normal 500 18px/1.2 Roboto;
        font-stretch: normal;
        text-align: left;
        color: $black;
        margin-bottom: 24px;
        &.add {
          margin-left: .75rem;
        }
      }
    }
  }
}
</style>
