<template lang="html">
  <div class="data second">
    <div class="input-border first">
      <label for="mobileUserAnyPacketBdsToBranchLatestPacketDate"></label>
      <span class="text">Maximální datum podání zásilky</span>
      <div class="group">
        <div class="border-gr short">
          <input :value="mobileUserAnyPacketBdsToBranchLatestPacketDate"
                 id="mobileUserAnyPacketBdsToBranchLatestPacketDate"
                 v-on:input="$emit('apply-mobile-user-any-packet-bds-to-branch-redirected-configuration', $event.target.value)"
                 type="text" autocomplete="nope" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import 'jquery-ui';
import 'jquery-ui/ui/widgets/datepicker';

export default {
  emits: [
      'apply-mobile-user-any-packet-bds-to-branch-redirected-configuration'
  ],
  props: [
      'mobileUserAnyPacketBdsToBranchLatestPacketDate',
  ],
  name: "MobileUserAnyPacketBdsToBranchRedirected",
  mounted() {
    this.refreshDatePicker();
  },
  updated() {
    this.refreshDatePicker();
  },
  methods: {
    refreshDatePicker() {
      $("#mobileUserAnyPacketBdsToBranchLatestPacketDate").datepicker({
        dateFormat: "yy-mm-dd"
      });
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_promocode.scss';
@import "~jquery-ui/themes/base/all.css";
</style>
