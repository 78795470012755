
import { defineComponent } from "vue";
import TheNumberOfUses from '@/components/UIComponents/PromocodeComponents/Configuration/Promocode/TheNumberOfUses.vue';
import TheNumberOfUsers from '@/components/UIComponents/PromocodeComponents/Configuration/Promocode/TheNumberOfUsers.vue';
import TheTypeOfCode from '@/components/UIComponents/PromocodeComponents/Configuration/Promocode/TheTypeOfCode.vue';
import TheTypeOfValue from '@/components/UIComponents/PromocodeComponents/Configuration/Promocode/TheTypeOfValue.vue';
import TheResults from '@/components/UIComponents/PromocodeComponents/Configuration/Promocode/TheResults.vue';
import TheCountry from "../UIComponents/PromocodeComponents/Configuration/Promocode/TheCountry.vue";
import TheName from "../UIComponents/PromocodeComponents/Configuration/Promocode/TheName.vue";
// eslint-disable-next-line no-unused-vars
import {Applier, Configuration, ConsumerGroup, EditPromocode, Promocode} from "@/models/Promocode/api.model";
import ThePromocodeList
  from "@/components/UIComponents/PromocodeComponents/PromocodeList/ThePromocodeList.vue";
import TheValue from "@/components/UIComponents/PromocodeComponents/Configuration/Promocode/TheValue.vue";
import TheFinalPrice from "@/components/UIComponents/PromocodeComponents/Configuration/Promocode/TheFinalPrice.vue";
import Loader from "@/components/UIComponents/Loader.vue";
import TheConsumerGroupList from "@/components/UIComponents/PromocodeComponents/ConsumerGroup/TheConsumerGroupList.vue";
import PromocodesModelConfig from "@/models/Promocode/config.model";
import EventResult from "@/components/UIComponents/PromocodeComponents/EventResult.vue";
import $ from "jquery";
import 'jquery-ui';
import 'jquery-ui/ui/widgets/datepicker';
import TheCodesCount from "../UIComponents/PromocodeComponents/Configuration/Promocode/TheCodesCount.vue";
import TheCodesCountOnePromocode from "../UIComponents/PromocodeComponents/Configuration/Promocode/TheCodesCountOnePromocode.vue";
import TheCodesCountMultiplePromocode from "../UIComponents/PromocodeComponents/Configuration/Promocode/TheCodesCountMultiplePromocode.vue";

export default defineComponent({
  name: 'ThePromocode',
  components: {
    EventResult,
    TheConsumerGroupList,
    Loader,
    TheValue,
    TheFinalPrice,
    ThePromocodeList,
    TheCountry,
    TheName,
    TheNumberOfUses,
    TheNumberOfUsers,
    TheTypeOfCode,
    TheTypeOfValue,
    TheResults,
    TheCodesCount,
    TheCodesCountOnePromocode,
    TheCodesCountMultiplePromocode
},
  mounted() {
    this.refreshDatePickers();
  },
  renderTriggered() {
    this.refreshDatePickers();
  },
  data: function () {
    return {
      promocodesModulConfig: null,
      loading: true,
      error: false,
      errorMsg: '',
      activeTab: 1,
      tabs: [
        {
          name: 'Tvorba kódu',
          order: 1
        },
        {
          name: 'Seznam kódů',
          order: 3
        },
        {
          name: 'Skupina konzumentů',
          order: 4
        },
      ],
      inputs: [
        {
          class: 'first',
          label: 'title-notification',
          title: 'Telefoní číslo (+420 XXX XXX XXX)',
        },
        {
          label: 'text-notification',
          title: 'Promokód (např. ZASILKOVNA2020)',
        }
      ],
      values: ['', ''],
      errorMessage: ['', ''],
      eventTitle: '',
      eventDescription: '',
      promocodeId: null,
      promocodeListId: null,
      type: 'simple',
      status: null,
      numberOfUses: 1,
      numberOfUsers: 100,
      applierId: 1,
      applierName: '',
      codesCount: 1,
      codesCountId: 1,
      codesCountName: 'Jeden promokód',
      showTitle: '',
      code: '',
      codeMask: '******',
      codeLenght: 6,
      showValue: 0,
      finalPrice: 0,
      showValueCurrency: '',
      appliers: Array<Applier>(),
      discounts: Array<Promocode>(),
      consumerGroups: Array<ConsumerGroup>(),
      nextDiscountId: 0,
      consignedCountries: Array<string>(),
      targetCountries: Array<string>(),
      dateFrom: '',
      dateTo: '',
      validity: null,
      validityTest: 'pending',
      checkMsg: '',
      mobileUserCountOfPackets: null,
      promocodeConsumerGroup: null,
      mobileUserAnyPacketBdsToBranchRedirected: false,
      mobileUserAnyPacketBdsToBranchLatestPacketDate: '',
      destinationTypes: Array<string>(),
      isEditing: false
    };
  },
  created: async function () {
    this.promocodesModulConfig = await PromocodesModelConfig.getConfiguration();
    await this.fetchPromocodes();
  },
  methods: {
    refreshDatePickers() {
      const self = this;
      $(function() {
        $("#dateFrom").datepicker({
          dateFormat: "yy-mm-dd",
          onSelect: function(dateText: string) {
            $('#dateFrom').attr('value', dateText);
            self.setDateFrom(dateText);
          }
        });
        $("#dateTo").datepicker({
          dateFormat: "yy-mm-dd",
          onSelect: function(dateText: string) {
            $('#dateTo').attr('value', dateText);
            self.setDateTo(dateText);
          }
        });
      });
    },
    printName(name: string) {
      this.showTitle = name;
    },
    printCode(code: string) {
      this.code = code;
    },
    printCodeMask(codeMask: string) {
      this.codeMask = codeMask;
    },
    printCodeType(title: string) {
      this.type = title;
    },
    printNumberOfUses(number: number) {
      this.numberOfUses = number
    },
    printNumberOfUsers(number: number) {
      this.numberOfUsers = number
    },
    printApplierId(applierId: number) {
      this.applierId = applierId;
    },
    printApplierName(applierName: string) {
      this.applierName = applierName;
    },
    printCodesCount(codesCount: number) {
      this.codesCount = codesCount;
    },
    printCodesCountId(codesCountId: number) {
      this.codesCountId = codesCountId;
    },
    printCodesCountName(codesCountName: string) {
      this.codesCountName = codesCountName;
    },
    printDiscountValue(discount: number) {
      this.showValue = discount;
    },
    printDiscountCurrency(currency: string) {
      this.showValueCurrency = currency;
    },
    printFinalPrice(finalPrice: number) {
      this.finalPrice = finalPrice;
    },
    startLoading: async function() {
      this.loading = true;
    },
    finishLoading: async function() {
      this.loading = false;
    },
    fetchPromocodes: async function () {
      await this.refreshAppliers();
      await this.refreshConsumerGroups();
      await this.refreshPromocodes();
    },
    refreshAppliers: async function() {
      this.$http.get<Applier[]>(this.promocodesModulConfig.apiBaseUrl + `promocodes/appliers`)
          .then((response) => {
            this.appliers = response.data;
            const defaultValue = this.appliers.find(function (item) {
              return item.id === 1;
            });

            if (defaultValue !== undefined) {
              this.applierName = defaultValue.name;
            }
          });
    },
    getApplierName(applierId: number): string {
      const applier = this.appliers.find(function(item: Applier) {
        return item.id === applierId;
      });
      return applier.name;
    },
    refreshPromocodes: async function(state = 'active') {
      this.discounts.splice(0,this.discounts.length);
      this.$http.get<Promocode[]>(this.promocodesModulConfig.apiBaseUrl + `promocodes/promocodes?status=` + state + `&order=desc`)
          .then((response) => {
            this.discounts = response.data;
            this.loading = false;
          });
    },
    refreshConsumerGroups: async function() {
      await this.startLoading();
      this.$http.get<ConsumerGroup[]>(this.promocodesModulConfig.apiBaseUrl + `promocodes/consumer-groups`)
          .then((response) => {
            this.consumerGroups = response.data;
            this.loading = false;
          });
    },
    saveDiscount() {
      if (!this.isValid()) {
        alert("Discount value must be positive!");
        return;
      } 
      if (this.promocodeId === null && this.promocodeListId === null) {
        const newPromocode = this.preparePromocodeModel('active', null, null);

        this.$http.post(
                this.promocodesModulConfig.apiBaseUrl + `promocodes/promocodes`,
                newPromocode
            )
            .then((response) => {
              if (response.status !== 200) {
                this.invokeError("CHYBA (STATUS: " + response.status + ") při ukládání promokódu: " + response.statusText);
              }
              this.fetchPromocodes();
              //this.discounts.unshift(response.data as Promocode);
              this.activeTab = 3;
              this.refreshDiscountForm();
              this.invokeSuccess('Hotovo!', 'Promokód byl úspěšně uložen.');
            })
            .catch((error) => {
              this.invokeError("CHYBA při ukládání promokódu: " + error);
            });
      } else {
        const editPromocode = this.preparePromocodeModel(this.status, this.promocodeId, this.promocodeListId);

        this.$http.put(
                this.promocodesModulConfig.apiBaseUrl + `promocodes/promocodes/` + this.promocodeId,
                editPromocode
            )
            .then((response) => {
              if (response.status !== 200) {
                this.invokeError("CHYBA (STATUS: " + response.status + ") při ukládání promokódu: " + response.statusText);
              }
              //this.discounts.unshift(response.data as Promocode);
              this.fetchPromocodes();
              this.activeTab = 3;
              this.refreshDiscountForm();
              this.invokeSuccess('Hotovo!', 'Promokód byl úspěšně uložen.');
            })
            .catch((error) => {
              this.invokeError("CHYBA při ukládání promokódu: " + error);
            });
      }
    },
    editDiscount(editPromocode: EditPromocode) {
      this.isEditing = true;
      if (editPromocode.promocode.code === null || editPromocode.promocode.code === '') {
        this.codesCountId = 2;
        this.codesCountName = 'Sada promokódů';
      } else {
        this.codesCountId = 1;
        this.codesCountName = 'Jeden promokód';
      }

      this.preparePromocodeFormFromModel(editPromocode.promocode);
      this.activeTab = 1;
      window.scrollTo(0,0);
    },
    refreshDiscountForm() {
      this.isEditing = false;
      this.promocodeId = null;
      this.promocodeListId = null;
      this.type = 'simple';
      this.status = null;
      this.numberOfUses = 1;
      this.numberOfUsers = 100;
      this.applierId = 1;
      this.applierName = '';
      this.showTitle = '';
      this.codesCount = 1;
      this.codesCountId = 1;
      this.codesCountName = '';
      this.code = null;
      this.codeMask = null;
      this.showValue = 0;
      this.finalPrice = 0;
      this.showValueCurrency = '';
      this.nextDiscountId = 0;
      this.consignedCountries = Array<string>();
      this.targetCountries = Array<string>();
      this.destinationTypes = Array<string>();
      this.dateFrom = '';
      this.dateTo = '';
      this.mobileUserCountOfPackets = null;
      this.promocodeConsumerGroup = null;
      this.mobileUserAnyPacketBdsToBranchRedirected = false;
      this.mobileUserAnyPacketBdsToBranchLatestPacketDate = ''
    },
    preparePromocodeFormFromModel(promocode: Promocode) {
      this.promocodeId = promocode.promocodeId;
      this.promocodeListId = promocode.promocodeListId;
      this.type = promocode.type;
      this.status = promocode.status;
      this.showTitle = promocode.name;
      this.code = promocode.code;
      this.codeMask = promocode.codeMask;
      this.codesCount = promocode.codesCount;
      this.consignedCountries = promocode.consignCountries;
      this.targetCountries = promocode.targetCountries;
      this.dateFrom = promocode.timeValidityFrom;
      this.dateTo = promocode.timeValidityTo;
      this.numberOfUses = promocode.promocodeUsedByUserLimit;
      this.numberOfUsers = promocode.promocodeTotalUseLimit;

      this.promocodeConsumerGroup = promocode.promocodeConsumerGroup;
      this.mobileUserCountOfPackets = promocode.mobileUserCountOfPackets;
      this.mobileUserAnyPacketBdsToBranchRedirected = promocode.mobileUserAnyPacketBdsToBranchRedirected;
      this.mobileUserAnyPacketBdsToBranchLatestPacketDate = promocode.mobileUserAnyPacketBdsToBranchLatestPacketDate;
      this.destinationTypes = promocode.destinationTypes;
      this.applierId = promocode.applierId;
      this.applierName = this.getApplierName(this.applierId);
      this.showValue = promocode.discount;
      this.showValueCurrency = promocode.currency;
      this.finalPrice = promocode.finalPrice;
    },
    preparePromocodeModel(status: string, promocodeId: number | null, promocodeListId: number | null): Promocode {
      return {
        promocodeId: promocodeId,
        promocodeListId: promocodeListId,
        type: this.type,
        status: status,
        name: this.showTitle,
        code: this.code,
        codeMask: this.codeMask,
        codesCount: this.codesCount,
        consignCountries: this.consignedCountries,
        targetCountries: this.targetCountries,
        timeValidityFrom: this.dateFrom,
        timeValidityTo: this.dateTo,
        promocodeUsedByUserLimit: Number(this.numberOfUses),
        promocodeTotalUseLimit: Number(this.numberOfUsers),

        promocodeConsumerGroup: Number(this.promocodeConsumerGroup),
        mobileUserCountOfPackets: Number(this.mobileUserCountOfPackets),
        mobileUserAnyPacketBdsToBranchRedirected: this.mobileUserAnyPacketBdsToBranchRedirected,
        mobileUserAnyPacketBdsToBranchLatestPacketDate: this.mobileUserAnyPacketBdsToBranchLatestPacketDate,
        destinationTypes: this.destinationTypes,

        applierId: Number(this.applierId),
        discount: Number(this.showValue),
        currency: this.showValueCurrency,
        rounding: false,
        finalPrice: Number(this.finalPrice)
      } as Promocode;
    },
    removeDiscount(index: number) {
      this.discounts.splice(index, 1)
    },
    toggleBtn() {
      if (this.values[0] === '' || this.values[1] === '') {
        this.validityTest = 'invalid'
        this.checkMsg = 'nemůže'
        this.errorMessage[0] = 'Účet nenalezen'
        this.errorMessage[1] = 'Promokód neexistuje'
      } else {
        this.validityTest = 'valid'
        this.checkMsg = 'může'
        this.errorMessage[0] = 'Účet nalezen'
        this.errorMessage[1] = 'Promokód existuje'
      }
    },
    tt() {
      if (this.values[0] === '') {
        this.validityTest = 'invalid'
      }
    },
    applyPromocodeConfiguration(configuration: Configuration) {
      this.mobileUserCountOfPackets = configuration.mobileUserCountOfPackets;
      this.promocodeConsumerGroup = configuration.promocodeConsumerGroup;
      this.mobileUserAnyPacketBdsToBranchRedirected = configuration.mobileUserAnyPacketBdsToBranchRedirected;
      this.mobileUserAnyPacketBdsToBranchLatestPacketDate = configuration.mobileUserAnyPacketBdsToBranchLatestPacketDate;
      this.destinationTypes = configuration.destinationTypes;
    },
    resetEvent() {
      this.eventTitle = '';
      this.eventDescription = '';
      this.error = false;
      this.errorMsg = '';
    },
    invokeError(message: string) {
      this.error = true;
      this.errorMsg = message;
      window.scrollTo(0,0);
    },
    invokeSuccess(title: string, message: string) {
      this.eventTitle = title;
      this.eventDescription = message;
      window.scrollTo(0,0);
    },
    setDateFrom(value: string) {
      this.dateFrom = value;
    },
    setDateTo(value: string) {
      this.dateTo = value;
    },
    isValid() {
      return (this.applierId === 4 && Number(this.finalPrice) > 0) 
          || (this.applierId !== 4 && Number(this.showValue) > 0);
    }
  }
});
