<template lang="html">
  <div class="input-border first">
    <div class="select-container">
      <span class="text">Měna slevy</span>
      <div class="group" @click="toggleInput()" v-detect-click="toggleInputOutside">
        <div class="wrap" :class="{ clearEdge : visible }">
          <div class="input" :class="{ edge : visible }">
            <span>{{ discountCurrency }}</span>
          </div>
        </div>
        <div class="arrow" :class="{ dropped : visible }"></div>
        <div :class="{ hidden : !visible, visible }">
          <ul>
            <li
                :class="{ lastLi : (currencies.length-1) === index , current : currency === discountCurrency }"
                v-for="(currency, index) in currencies"
                :key="currency"
                v-on:click="$emit('apply-discount-currency', currency)"
            >
              {{ currency }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: [
      'apply-discount-currency'
  ],
  props: [
      'discountCurrency'
  ],
  name: "TheValueCurrency",
  data() {
    return {
      currencies: ["CZK", "EUR", "HUF", "RON"],
      visible: false,
    };
  },
  methods: {
    toggleInput() {
      this.visible = !this.visible;
    },
    toggleInputOutside() {
      this.visible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_container.scss";
</style>