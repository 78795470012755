<template lang="html">
  <div class="data second">
    <div class="input-border first">
      <label for="date"></label>
      <span class="text">Maximální počet odeslaných zásilek</span>
      <div class="group">
        <div class="border-gr short date">
          <input :value="mobileUserCountOfPackets"
                 v-on:input="$emit('apply-new-customer-configuration', $event.target.value)"
                 name="mobileUserCountOfPackets" type="text" autocomplete="nope" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['apply-new-customer-configuration'],
  props: ['mobileUserCountOfPackets'],
  name: "NewCustomer"
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_promocode.scss';
</style>
