
import {defineComponent} from "vue";
import PromocodesModelConfig from "@/models/Promocode/config.model";


export default defineComponent({
  emits: [
    'show-add-consumer',
    'show-consumers-list',
  ],
  data() {
    return {
      file: null as File | null,
      promocodesModuleConfig: null
    };
  },
  created: async function() {
    this.promocodesModuleConfig = await PromocodesModelConfig.getConfiguration();
  },
  name: "TheConsumerGroupListItem",
  props: {
    consumerGroup: Object,
    index: Number
  },
  methods: {
    onFileChange(e: Event): void {
      const files = (e.target as HTMLInputElement).files;
      if (files && files.length) {
        this.file = files[0];
      } else {
        this.file = null;
      }
    },
    async uploadFile(): Promise<void> {
      if (this.file) {
        const formData = new FormData();
        formData.append('file', this.file);
        
          this.$http.post(this.promocodesModuleConfig.apiBaseUrl + `promocodes/consumer-groups/` + this.consumerGroup.id + `/import` , formData)
              .then((res) => {
                if (res.status !== 200) {
                  alert('Failed to upload file.');
                } else {
                  alert('File uploaded successfully.');
                }
              })
      } else {
        alert('Please select a file to upload.');
      }
    }
  }
});
