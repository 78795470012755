
import { defineComponent } from "vue";
// eslint-disable-next-line no-unused-vars
import {Consumer} from "@/models/Promocode/api.model";
import PromocodesModelConfig from "@/models/Promocode/config.model";

export default defineComponent({
  emits: [
      'refresh-consumers',
      'invoke-success',
      'invoke-error',
  ],
  name: "TheConsumerGroupListItem",
  props: {
    consumer: Object,
    index: Number
  },
  data() {
    return {
      promocodesModulConfig: null
    };
  },
  created: async function() {
    this.promocodesModulConfig = await PromocodesModelConfig.getConfiguration();
  },
  methods: {
    removeConsumer(index: number, consumer: Consumer) {
      this.$http.delete(this.promocodesModulConfig.apiBaseUrl + `promocodes/consumer-groups/` + consumer.consumerGroupId + `/consumers/` + consumer.id)
          .then((response) => {
            if (response.status === 204) {
              this.$emit(
                  'refresh-consumers',
                  consumer.consumerGroupId
              );
              this.$emit(
                  'invoke-success',
                  'Hotovo!',
                  'Uživatel byl odebrán.'
              );
            } else {
              this.$emit(
                  'invoke-error',
                  "CHYBA při ukládání změny: " + response.statusText
              );
            }
          })
          .catch((error) => {
            this.$emit(
                'invoke-error',
                "CHYBA při ukládání změny: " + error
            );
          });
    }
  },
});
