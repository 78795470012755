<template lang="html">
    <div class="input-border first">
        <label for="code"></label>
        <span class="text">Počet znaků generovaného promokódu</span>
        <div class="group">
        <div class="border-gr short">
            <input name="code" type="text" autocomplete="false" :readonly="isEditing"
                    v-on:change="generateCodeMask($event.target.value)"
                    :value="codeLength" />
        </div>
        </div>
    </div>

    <div class="input-border first">
        <label for="codesCount"></label>
        <span class="text">Počet kódů (k vygenerování)</span>
        <div class="group">
        <div class="border-gr short">
            <input name="codesCount" type="text" autocomplete="false" :readonly="isEditing"
                    v-on:input="$emit('print-codes-count', Number($event.target.value))"
                    :value="codesCount" />
        </div>
        </div>
    </div>
</template>
  
  <script>
  export default {
    emits: ['print-code-mask', 'print-codes-count'],
    props: {
      codeLength: {
        type: Number
      },
      codesCount: {
        type: Number
      },
      isEditing: {
        type: Boolean
      }
    },
    name: "TheCodesCountMultiplePromocode",
    methods: {
      generateCodeMask(length) {
        let codeMask = '';
        for (let i = 0; i < length; i++) {
          codeMask = codeMask + '*';
        }

        this.$emit(
          'print-code-mask',
          codeMask
        );
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";
  @import "@/assets/styles/_promocode.scss";
  </style>