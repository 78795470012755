
import { defineComponent } from "vue";
// eslint-disable-next-line no-unused-vars
import {Consumer} from "@/models/Promocode/api.model";
import PromocodesModelConfig from "@/models/Promocode/config.model";

export default defineComponent({
  emits: [
      'show-consumers-list',
      'invoke-error',
      'invoke-success',
  ],
  props: [
      'consumerGroupId',
      'consumerGroupName',
  ],
  name: "TheAddConsumer",
  data() {
    return {
      promocodesModulConfig: null,
      name: '',
      email: '',
      phone: '',
    };
  },
  created: async function() {
    this.promocodesModulConfig = await PromocodesModelConfig.getConfiguration();
  },
  methods: {
    addConsumer: async function(consumerGroupId: number, consumerGroupName: string) {
      const consumer = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        consumerGroupId: consumerGroupId
      } as Consumer;

      this.$http.put(this.promocodesModulConfig.apiBaseUrl + `promocodes/consumer-groups/` + consumerGroupId + `/consumers`, consumer)
          .then((response) => {
            if (response.status !== 200) {
              this.$emit(
                  'invoke-error',
                  "CHYBA při ukládání změny: " + response.statusText
              );
            }
            this.$emit(
                'show-consumers-list',
                { id: consumerGroupId, name: consumerGroupName }
            );
            this.$emit(
                'invoke-success',
                'Hotovo!',
                'Nový uživatel byl přidán do skupiny uživatelů ' + consumerGroupName
            );
          })
          .catch((error) => {
            this.$emit(
                'invoke-error',
                "CHYBA při ukládání změny: " + error
            );
          });
    }
  }
});
