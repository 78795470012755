<template lang="html">
  <div class="select-container">
    <span class="text">Typ slevy</span>
    <div class="group" @click="toggleInput()" v-detect-click="toggleInputOutside">
      <div class="wrap" :class="{ clearEdge : visible }">
        <div class="input" :class="{ edge : visible }">
          <span>{{ applierName }}</span>
        </div>
      </div>
    <div class="arrow" :class="{ dropped : visible }"></div>
      <div :class="{ hidden : !visible, visible }">
        <ul>
          <li
            :class="{ lastLi : (promocodeAppliers.length-1) === index , current : value.title === applierId }"
            v-for="(value, index) in promocodeAppliers"
            :key="value"
            @click="select(
              value.id,
              value.name
            );"
          >
            {{ value.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['print-applier-id', 'print-applier-name'],
  props: {
    applierId: {
      type: Number
    },
    applierName: {
      type: String
    },
    promocodeAppliers: {
      type: Array
    }
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
		toggleInput() {
			this.visible = !this.visible;
		},
    toggleInputOutside() {
      this.visible = false;
    },
    select(id, name) {
      this.$emit(
          'print-applier-id',
          id
      );
      this.$emit(
          'print-applier-name',
          name
      );
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import "@/assets/styles/_container.scss";
</style>
