<template lang="html">
    <div class="container">
      <div class="text">
        Typ cílového místa
      </div>
      <div class="group">
        <div class="platform" :class="entity.class" v-for="entity in entities" :key="entity" >
          <label :for="'destinationTypes-' + entity.label" class="label-checkbox">
            <input
              v-on:change="addDestinationType(destinationTypes, entity.label)"
              :true-value="entity.label"
              type="checkbox" 
              :id="'destinationTypes-' + entity.label" 
              name="destinationTypes"
              :checked="destinationTypes.includes(entity.label)"
            />
            <span class="checkmark"></span>
          </label>
          <span class="text add">{{ entity.title }}</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    emits: ["apply-destination-type-configuration"],
    props: {
      modelValue: String,
      destinationTypes: Array
    },
    data() {
      return {
        entities: [
          {
            class: 'point',
            label: 'point',
            title: 'point'
          },
          {
            class: 'carrier',
            label: 'carrier',
            title: 'carrier'
          },
          {
            class: 'zbot',
            label: 'zbot',
            title: 'zbot'
          },
          {
            class: 'zbox',
            label: 'zbox',
            title: 'zbox'
          }
        ]
      };
    },
    methods: {
      addDestinationType(destinationTypes, destinationType) {
        if (!destinationTypes.includes(destinationType)) {
          destinationTypes.push(destinationType);
        } else {
          destinationTypes.forEach( (item, index) => {
            if(item === destinationType) destinationTypes.splice(index,1);
          });
        }
      },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @import '@/assets/styles/_variables.scss';
  .container {
    margin-top: 40px;
    .text {
      font: normal normal 14px/1.2 Roboto;
      font-stretch: normal;
      letter-spacing: 0.14px;
      text-align: left;
      color: $darkergray;
      margin-bottom: 24px;
      &.add {
        margin-left: .75rem;
      }
    }
    .group {
      display: flex;
      .platform {
        &.point {
          padding-right: 48px;
        }
        &.carrier {
          padding-right: 38px;
        }
        &.zbox {
          padding-right: 38px;
        }
        &.zbot {
          padding-right: 38px;
        }
        .label-checkbox {
          position: relative;
          cursor: pointer;
          input[type=checkbox], input[type=radio] {
            opacity: 0;
            &:checked {
              ~ .checkmark {
                background-color: $red;
                border: 1px solid $red;
                &:after {
                  display: block;
                }
              }
            }
          }
          .checkmark {
            position: absolute;
            top: -2px;
            left: 0;
            height: 18px;
            width: 20px;
            background-color: $white;
            border: 1px solid $gray;
            &::after {
              content: "";
              position: absolute;
              display: none;
              left: 7px;
              top: 1px;
              width: 3px;
              height: 10px;
              border: solid $white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
        .text {
          font: normal 500 18px/1.2 Roboto;
          font-stretch: normal;
          text-align: left;
          color: $black;
          margin-bottom: 24px;
          &.add {
            margin-left: .75rem;
          }
        }
      }
    }
  }
  </style>
  