
import { defineComponent } from "vue";
// eslint-disable-next-line no-unused-vars
import { Type } from "@/models/Promocode/api.model";
import NewCustomer
  from "@/components/UIComponents/PromocodeComponents/Configuration/Promocode/PromocodeType/NewCustomer.vue";
import Employee from "@/components/UIComponents/PromocodeComponents/Configuration/Promocode/PromocodeType/Employee.vue";
import DestinationTypes from "@/components/UIComponents/PromocodeComponents/Configuration/Promocode/PromocodeType/DestinationTypes.vue";
import MobileUserAnyPacketBdsToBranchRedirected
  from "@/components/UIComponents/PromocodeComponents/Configuration/Promocode/PromocodeType/MobileUserAnyPacketBdsToBranchRedirected.vue";
import PromocodesModelConfig from "@/models/Promocode/config.model";

export default defineComponent({
  components: { MobileUserAnyPacketBdsToBranchRedirected, Employee, NewCustomer, DestinationTypes },
  emits: ['print-code-type', 'apply-configuration'],
  props: {
    promocodeType: {
      type: String
    },
    mobileUserCountOfPackets: {
      type: Number
    },
    promocodeConsumerGroup: {
      type: Number
    },
    consumerGroups: {
      type: Array
    },
    mobileUserAnyPacketBdsToBranchLatestPacketDate: {
      type: String
    },
    destinationTypes: {
      type: Array
    }
  },
  data: function () {
    return {
      promocodesModulConfig: null,
      defaultTitle: '',
      types: Array<Type>(),
      visible: false,
      configuration: {
        mobileUserCountOfPackets: 0,
        promocodeConsumerGroup: 0,
        mobileUserAnyPacketBdsToBranchRedirected: false,
        mobileUserAnyPacketBdsToBranchLatestPacketDate: '',
        destinationTypes: Array<string>()
      }
    };
  },
  created: async function () {
    this.promocodesModulConfig = await PromocodesModelConfig.getConfiguration();
    await this.fetchTypes();
  },
  methods: {
		toggleInput() {
			this.visible = !this.visible;
		},
    toggleInputOutside() {
      this.visible = false;
    },
    select(title: string) {
      this.$emit(
        'print-code-type',
        this.defaultTitle = title
      );
		},
    fetchTypes: async function () {
      this.$http.get<Type[]>(this.promocodesModulConfig.apiBaseUrl + `promocodes/types`)
          .then((response) => {
            this.types = response.data;
          });
    },
    getApplierName(type: string | null): string {
      if (type === null) {
        type = 'simple';
      }

      const typeModel = this.types.find(function(item) {
        return item.type === type;
      });

      if (typeModel === undefined) {
        return '';
      }
      return typeModel.name;
    },
    applyNewCustomerConfiguration(mobileUserCountOfPackets: number) {
      this.configuration.mobileUserCountOfPackets = mobileUserCountOfPackets;
      this.$emit(
          'apply-configuration',
          this.configuration
      );
    },
    applyEmployeeConfiguration(promocodeConsumerGroup: number) {
      this.configuration.promocodeConsumerGroup = promocodeConsumerGroup;
      this.$emit(
          'apply-configuration',
          this.configuration
      );
    },
    applyMobileUserAnyPacketBdsToBranchRedirectedConfiguration(
        mobileUserAnyPacketBdsToBranchLatestPacketDate: string
    ) {
      this.configuration.mobileUserAnyPacketBdsToBranchRedirected = mobileUserAnyPacketBdsToBranchLatestPacketDate !== '';
      this.configuration.mobileUserAnyPacketBdsToBranchLatestPacketDate = mobileUserAnyPacketBdsToBranchLatestPacketDate;
      this.$emit(
          'apply-configuration',
          this.configuration
      );
    },
    applyDestinationTypeConfiguration(destinationTypes: Array<string>) {
      alert(destinationTypes.length);
      this.configuration.destinationTypes = destinationTypes;
      this.$emit(
          'apply-configuration',
          this.configuration
      );
    }
	},
});
