
import { defineComponent } from "vue";

// eslint-disable-next-line no-unused-vars
import {DropdownItem, EditPromocode} from "@/models/Promocode/api.model";
import ThePromocodeListItem from "@/components/UIComponents/PromocodeComponents/PromocodeList/ThePromocodeListItem.vue";
import DropdownSelector from "@/components/UIComponents/DropdownSelector.vue";


export default defineComponent({
  components: {ThePromocodeListItem, DropdownSelector},
  emits: [
      'refresh-promocodes',
      'start-loading',
      'edit-promocode',
      'invoke-success',
      'invoke-error',
  ],
  name: "ThePromocodeList",
  props: [
      'promocodes',
      'appliers'
  ],
  data() {
    return {
      promocodeStates: [
        {
          id: 0,
          name: `Aktivni`,
          value: `active`
        },
        {
          id: 1,
          name: `Neaktivni`,
          value: `inactive`
        },
      ],
    }
  },
  methods: {
    removePromocodeFromList() {
      this.$emit(
          'start-loading'
      );
      this.$emit(
          'refresh-promocodes'
      );
    },
    editPromocode(value: EditPromocode) {
      this.$emit(
          'edit-promocode',
          value
      );
    },
    invokeError(message: string) {
      this.$emit(
          'invoke-error',
          message
      );
    },
    invokeSuccess(title: string, message: string) {
      this.$emit(
          'invoke-success',
          title,
          message
      );
    },
    onItemSelect(item: DropdownItem) {
      this.$emit(
          'start-loading'
      );
      
      this.$emit(
          'refresh-promocodes',
          item.value
      );
    },
  }
});
