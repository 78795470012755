<template lang="html">
    <div class="select-container">
      <span class="text">Počet promokódů</span>
      <div class="group" @click="toggleInput()" v-detect-click="toggleInputOutside">
        <div class="wrap" :class="{ clearEdge : visible }">
          <div class="input" :class="{ edge : visible }">
            <span>{{ codesCountName }}</span>
          </div>
        </div>
      <div class="arrow" :class="{ dropped : visible }"></div>
        <div :class="{ hidden : !visible, visible }">
          <ul>
            <li
              :class="{ lastLi : (getCodesCounts().length-1) === index , current : value.id === codesCountId }"
              v-for="(value, index) in getCodesCounts()"
              :key="value"
              @click="select(
                value.id,
                value.name
              );"
            >
              {{ value.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    emits: ['print-codes-count-id', 'print-codes-count-name', 'print-codes-count', 'print-code', 'print-code-mask'],
    props: {
      codesCountId: {
        type: Number
      },
      codesCountName: {
        type: String
      },
      isEditing: {
        type: Boolean
      }
    },
    data() {
      return {
        visible: false,
      };
    },
    methods: {
      toggleInput() {
        this.visible = !this.visible;
      },
      toggleInputOutside() {
        this.visible = false;
      },
      select(id, name) {
        if (this.isEditing) {
          alert("Toto nastavení nelze změnit.");
          return;
        }

        this.$emit(
            'print-codes-count-id',
            id
        );
        this.$emit(
            'print-codes-count-name',
            name
        );

        if (id === 1) {
          this.$emit(
            'print-code-mask',
            '******'
          );
          this.$emit(
            'print-codes-count',
            1
          );
        }

        if (id === 2) {
          this.$emit(
            'print-code',
            ''
          );
        }
      },
      getCodesCounts() {
        return [
            {
                id: 1,
                name: 'Jeden promokód'
            },
            {
                id: 2,
                name: 'Sada promokódů'
            }
        ];
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import '@/assets/styles/_variables.scss';
  @import "@/assets/styles/_container.scss";
  </style>
  