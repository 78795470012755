<template lang="html">
  <div class="data second">
    <div class="input-border first">
      <label for="title"></label>
      <span class="text">Název promokódu (kampaň) (max. 100 znaků)</span>
      <div class="group">
        <div class="border-gr short">
          <input :value="name"
                 v-on:input="$emit('print-name', $event.target.value)"
                 type="text" autocomplete="nope" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: [
      'print-name'
  ],
  props: [
      'name'
  ],
  name: "TheName"
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_promocode.scss";
</style>