
import { defineComponent } from "vue";
// eslint-disable-next-line no-unused-vars
import {Consumer, ConsumerGroup} from "@/models/Promocode/api.model";
import TheConsumerGroupListItem
  from "@/components/UIComponents/PromocodeComponents/ConsumerGroup/TheConsumerGroupListItem.vue";
import TheConsumerListItem from "@/components/UIComponents/PromocodeComponents/Consumer/TheConsumerListItem.vue";
import TheAddConsumer from "@/components/UIComponents/PromocodeComponents/Consumer/TheAddConsumer.vue";
import TheAddConsumerGroup from "@/components/UIComponents/PromocodeComponents/ConsumerGroup/TheAddConsumerGroup.vue";
import PromocodesModelConfig from "@/models/Promocode/config.model";

export default defineComponent({
  components: {TheAddConsumerGroup, TheAddConsumer, TheConsumerListItem, TheConsumerGroupListItem},
  emits: [
    'refresh-consumer-groups',
    'start-loading',
    'finish-loading',
    'invoke-error',
    'invoke-success',
  ],
  name: "TheConsumerGroupList",
  props: [
    'consumerGroups'
  ],
  data() {
    return {
      promocodesModulConfig: null,
      consumersList: false,
      consumerAdd: false,
      consumerGroupAdd: false,
      selectedConsumerGroup: null,
      selectedConsumerGroupId: null,
      selectedConsumerGroupName: null,
      consumers: Array<Consumer>(),
      consumerGroupList: this.consumerGroups
    };
  },
  created: async function() {
    this.promocodesModulConfig = await PromocodesModelConfig.getConfiguration();
  },
  methods: {
    fetchConsumers: async function(consumerGroupId: number) {
      this.consumerAdd = false;
      this.selectedConsumerGroup = this.getConsumerGroupById(consumerGroupId);

      this.$http.get<Consumer[]>(this.promocodesModulConfig.apiBaseUrl + `promocodes/consumer-groups/` + consumerGroupId + `/consumers`)
          .then((response) => {
            this.consumers = response.data;
            this.consumersList = true;
            this.$emit('finish-loading');
          });
    },
    getConsumerGroupById(consumerGroupId: number): ConsumerGroup {
      return this.consumerGroupList.find(function(item: ConsumerGroup) {
        return item.id == consumerGroupId;
      });
    },
    showConsumersList: async function(consumerGroup: ConsumerGroup) {
      this.consumerAdd = false;
      this.selectedConsumerGroupId = consumerGroup.id;
      this.selectedConsumerGroupName = consumerGroup.name;
      this.$emit('start-loading');
      await this.fetchConsumers(consumerGroup.id);
    },
    refreshConsumers: async function(consumerGroupId: number) {
      this.consumers.splice(0,this.consumers.length);
      this.$emit('start-loading');
      await this.fetchConsumers(consumerGroupId);
    },
    showAddConsumer: async function(consumerGroup: ConsumerGroup) {
      this.selectedConsumerGroupId = consumerGroup.id;
      this.selectedConsumerGroupName = consumerGroup.name;
      this.consumerAdd = true;
      this.consumersList = false;
    },
    showAddConsumerGroup: async function() {
      this.consumersList = false;
      this.consumerGroupAdd = true;
      this.consumerAdd = false;
    },
    refreshConsumerGroupsList() {
      this.$emit(
          'refresh-consumer-groups'
      );
      this.consumersList = false;
      this.consumerGroupAdd = false;
      this.consumerAdd = false;
    },
    invokeError(message: string) {
      this.$emit(
          'invoke-error',
          message
      );
    },
    invokeSuccess(title: string, message: string) {
      this.$emit(
          'invoke-success',
          title,
          message
      );
    },
  },
});
