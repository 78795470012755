
import {defineComponent} from "vue";


export default defineComponent({
  name: 'DropdownSelector',
  props: {
    label: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    displayTextKey: {
      type: String,
      default: 'name'
    },
    returnItemKey: {
      type: String,
      default: 'id'
    }
  },
  data() {
    return {
      selectedItem: null
    };
  },
  created() {
    this.selectedItem = this.items[0];
    this.onSelect();
  },
  methods: {
    onSelect() {
      this.$emit('select', this.selectedItem);
    }
  }
});
