<template lang="html">
    <div class="input-border first">
      <label for="value"></label>
      <span class="text">Kód promokódu</span>
      <div class="group">
        <div class="border-gr short">
          <input name="code" type="text" autocomplete="false"
                 v-on:input="$emit('print-code', $event.target.value)"
                 :value="code" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    emits: ['print-code'],
    props: [
      'code'
    ],
    name: "TheCodesCountOnePromocode"
  }
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";
  @import "@/assets/styles/_promocode.scss";
  </style>