
import { defineComponent } from "vue";
// eslint-disable-next-line no-unused-vars
import {ConsumerGroup} from "@/models/Promocode/api.model";

export default defineComponent({
  emits: ['apply-employee-configuration'],
  props: ['promocodeConsumerGroup', 'consumerGroups'],
  name: "Employee",
  data: function () {
    return {
      visible: false,
    };
  },
  methods: {
    toggleInput() {
      this.visible = !this.visible;
    },
    toggleInputOutside() {
      this.visible = false;
    },
    getConsumerGroupName(groups: Array<ConsumerGroup>, groupId: number): string {
      const consumerGroup = groups.find(function(item) {
        return item.id == groupId;
      });
      return consumerGroup.name;
    }
  }
});
